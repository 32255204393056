<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-6">
        Write the balanced molecular equation for each of the chemical reactions described below:
      </p>

      <p class="mb-3">
        a) Solid potassium chlorate,
        <chemical-latex content="KClO3" />, decomposes to form solid potassium chloride and diatomic
        oxygen gas.
      </p>
      <chemical-notation-input
        v-model="inputs.reactionA"
        dense
        :show-note="false"
        class="mb-7"
        :disabled="!allowEditing"
      />

      <p class="mb-3">
        b) Solid aluminum metal reacts with solid diatomic iodine to form solid
        <chemical-latex content="Al2I6" />.
      </p>
      <chemical-notation-input
        v-model="inputs.reactionB"
        dense
        :show-note="false"
        class="mb-7"
        :disabled="!allowEditing"
      />

      <p class="mb-3">
        c) When solid sodium chloride is added to aqueous sulfuric acid, hydrogen chloride gas and
        aqueous sodium sulfate are produced.
      </p>
      <chemical-notation-input
        v-model="inputs.reactionC"
        dense
        :show-note="false"
        class="mb-7"
        :disabled="!allowEditing"
      />

      <p class="mb-3">
        d) Aqueous solutions of phosphoric acid and potassium hydroxide react to produce aqueous
        potassium dihydrogen phosphate and liquid water.
      </p>
      <chemical-notation-input
        v-model="inputs.reactionD"
        dense
        :show-note="false"
        class="mb-7"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput';
import ChemicalLatex from '../displayers/ChemicalLatex';

export default {
  name: 'Question224',
  components: {
    ChemicalNotationInput,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        reactionA: null,
        reactionB: null,
        reactionC: null,
        reactionD: null,
      },
    };
  },
};
</script>
